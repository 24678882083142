jQuery(document).ready(function() {
  var dataController = $('body').data('controller');
  var dataAction = $('body').data('action');
  if (dataController == "pages/accounts" && (dataAction == "verify" || dataAction == "verified")) {
    var spn = document.getElementById("count");
    var btn = document.getElementById("btnCounter");
    var count = 60;     // Set count
    var timer = null;  // For referencing the timer

    (function countDown(){
      // Display counter and start counting down
      spn.textContent = "(" + count + ")";
      
      // Run the function again every second if the count is not zero
      if(count !== 0){
        timer = setTimeout(countDown, 1000);
        count--; // decrease the timer
      } else {
        // Enable the button
        $("#count").text("");
        $("#btnCounter").prop("href", "/accounts/resend_verify_code?token=" + $("#token_input").text());
        $("#btnCounter span").removeClass("text-muted");
        $("#btnCounter").closest(".card_notice").removeClass("disabledbutton");
        btn.removeAttribute("disabled");
      }
    }());
  }
});