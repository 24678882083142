window.jQuery = $;
window.$ = $;
// window.Highcharts = Highcharts;

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("bootstrap")
import "./pages/plugin/jquery/jquery.js"
require("chart.js")
import "./pages/plugin/jquery-easing/jquery.easing.js"
import "./pages/plugin/select2"
import "./pages/js/six_digit_authorization.js"
import "./pages/js/contdown-to-enable.js"

require('datatables.net-bs')(window, $)
require('datatables.net-buttons-bs')(window, $)
require('datatables.net-buttons/js/buttons.colVis.js')(window, $)
require('datatables.net-buttons/js/buttons.html5.js')(window, $)
require('datatables.net-buttons/js/buttons.print.js')(window, $)
require('datatables.net-responsive-bs')(window, $)
require('datatables.net-select')(window, $)

// require("jquery-knob")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
