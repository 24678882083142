jQuery(document).ready(function() {

  jQuery('.mm-number-input-item:nth-child(1) input').focus();

  jQuery('.mm-number-input-item').each(function(index) {
    var item, id, input;
    item = jQuery(this);
    id = index + 1;
    input = item.children('input');
    item.addClass('mm-number-input-item-'+id);
    input.data('id',id);
  });

  jQuery('.mm-number-input-item input').on('keyup', function(e) {

    var item, value, id, count, pass = [];
    item = jQuery(this);
    value = item.val();
    id = item.data('id');
    count = jQuery('.mm-number-input-item').length;

    if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
      jQuery('.mm-number-input-item-'+(id)+' input').val('');
    }
    else {
      if(!value) {
        jQuery('.mm-number-input-item-'+id+' input').focus().select();
      }
      else {
        if(id < count) {
          jQuery('.mm-number-input-item-'+(id+1)+' input').focus().select();
        }
        else {
          //
        }
      }

      jQuery('.mm-number-input-item input').each(function() {
        var x;
        x = jQuery(this);
        pass.push(x.val());
      });

      var number = pass.join("");
    }
    if(id === count) {
      $("#account_confirmation_code_param").val(number);
      $("#verify_confirm_btn").click();
    }
  });
});
